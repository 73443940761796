import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import CenterInnerContent from "../components/modules/center-inner-content"
import NumberedList from "../components/modules/numbered-list.js"
import CaseResults from "../components/partials/case-results.js"
import ContentHeading from "../components/modules/content-heading.js"
import { getImage } from "../../util/functions"

const WhitePapersIndex = ({ data }) => {
  //highlight-line
  const page = data.wpPage
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  const whitePapers = data.allWpPage?.edges
  const meta = [robots]
  const seo = page.seo

  const addToMeta = (name, content) => {
    if (content) meta.push({ name: name, content: content })
  }
  addToMeta("og:site_name", seo.opengraphSiteName)
  addToMeta(
    "og:image",
    seo.opengraphImage?.localFile?.publicURL
      ? getImage(seo.opengraphImage.localFile.publicURL, true, false)
      : null
  )
  addToMeta("article:publisher", seo.opengraphPublisher)
  addToMeta("article:modified_time", seo.opengraphModifiedTime)
  addToMeta("article:published_time", seo.opengraphPublishedTime)
  addToMeta("og:type", seo.opengraphType)

  // Filter out useless outside node object in the edges list
  let whitePapersList
  whitePapers
    ? (whitePapersList = whitePapers.map(({ node }) => {
        return { title: node.title, url: node.link }
      }))
    : (whitePapersList = [])
  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.metaDesc}
        meta={meta}
        schema={page.seo.schema?.raw}
      />
        <CenterInnerContent spacing hideMenu inject={false} bgText="MARRONE">
          <ContentHeading h1>White Papers</ContentHeading>
            {unlock => (
              <h3
                tw="transition-all duration-500 ease-in-out"
              >
                Table of Contents:
              </h3>
            )}
          <NumberedList items={whitePapersList} />
        </CenterInnerContent>
      <CaseResults centered />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    # Pages to list as white pages
    allWpPage(filter: { template: { templateName: { eq: "White Paper" } } }) {
      edges {
        node {
          title
          link
        }
      }
    }
    #
    wpPage(id: { eq: $id }) {
      seo {
        ...SEO
        ...PostSEO
      }
    }
  }
`
export default WhitePapersIndex
